* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Assistant", sans-serif !important;
}
img {
  max-width: 100%;
  height: auto;
}
.App {
  min-height: 100vh;
  width: 85vw;
  max-width: 1900px;
  margin: 0rem auto;
}

body {
  max-width: 100%;
  overflow-x: hidden; 
  position: relative;
  background-color: #ffffff;

}


nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
}
.navbar-menu-container {
  display: none;
}
.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: black;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 1.6rem;
}
.primary-button {
  padding: 0.7rem 0.7rem;
  background-color: rgb(0, 0, 0);
  color: white;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 500;
  transition: 0.2s;
}

.primary-button:hover {
  background-color: rgb(255, 255, 255);
  color: #000000;
}
.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 6rem;
}
.home-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 700px;
}
.home-image-section {
  max-width: 600px;
  flex: 1;
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.home-image-container img {
  width: 100%; 
  max-width: 700px; 
  height: auto; 
}
.work-bannerImage-container {
  position: absolute;
  top: 3500px;
  right: -400px;
  z-index: -2;
  max-width: 700px;
  transform: rotate(40deg) !important;
}
.primary-heading {
  font-size: clamp(2rem, 5vw, 4rem);
  color: #000000;
  max-width: 600px;
}
.primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  color: #040404;
  margin: 1.5rem 0rem;
}
.secondary-button {
  padding: 0.9rem 1.6rem;
  background-color: #000000;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.4rem;
  cursor: pointer;
  font-weight: 500;
  color: rgb(255, 255, 255);
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}
.secondary-button:hover {
  background-color: #ffffff;
  color: #000000;
}
.work-background-image-container {
  position: absolute;
  right: 100px;
  z-index: -2;
}
.about-section-container {
  margin-top: 20rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-background-image-container {
  position: absolute;
  left: -150px;
  z-index: -2;
}
.about-section-image-container {
  flex: 0.9;
  margin-right: 1rem;
}
.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.primary-subheading {
  font-weight: 700;
  color: #0d79fe;
  font-size: 1.15rem;
}
.about-buttons-container {
  margin-top: 2rem;
  display: flex;
}
.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}
.contact-page-wrapper h1 {
  max-width: 900px !important;
}
.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 6rem 0rem;
}
.contact-form-container {
  background-color: rgb(255, 255, 255);
  max-width: 700px;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5rem;
}
.contact-form-container input {
  flex: 1;
  height: 100%;
  border-color: black;
  border-radius: 10%;
  outline: none;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
}

.testimonial-section-bottom {
  margin-top: 2rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
}
.testimonial-section-bottom {
  margin: 2rem auto;
}
.testimonial-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
}
.testimonials-stars-container svg {
  margin: 0rem 0.25rem;
  font-size: 1.5rem;
  color: #0d45fe;
}
.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}
.work-section-wrapper {
  margin-top: 15rem;
}
.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}
.work-section-top h1 {
  max-width: 700px !important;
}
.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.work-section-info {
  width: 290px;
  min-height: 350px;
  background-color: rgb(225, 225, 225);
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #000000;
  margin: 1rem 2rem;
}
.work-section-info h2 {
  margin: 1rem 0rem;
}
.work-section-bottom {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.work-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}

@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .primary-button {
    font-size: 1rem;
  }
  .home-bannerImage-container {
    max-width: 600px;
  }
  .work-bannerImage-container{
    max-width: 600px;
  }
}
@media (max-width: 800px) {
  .nav-logo-container {
    max-width: 140px;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
  }
  .home-bannerImage-container {
    max-width: 450px;
  }
  .work-bannerImage-container{
    max-width: 450px;
  }
  .home-banner-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .about-section-container {
    flex-direction: column;
  }
  .home-image-section,
  .about-section-image-container {
    width: 100%;
    max-width: 400px;
  }
  .primary-heading {
    text-align: center;
    max-width: 90%;
  }
  .primary-text {
    text-align: center;
    max-width: 80%;
  }
  .home-text-section,
  .about-section-text-container {
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }
  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  .about-section-container {
    margin-top: 5rem !important;
  }
  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
  }
  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }
  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }
  .about-section-image-container {
    margin-right: 0rem !important;
  }
  .work-section-wrapper {
    margin-top: 5rem !important;
  }
  .work-section-bottom {
    margin-top: 1rem !important;
  }
  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;

  }
  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }

  .App {
    max-width: 95vw;
  }
}
@media (max-width: 600px) {
  .home-bannerImage-container,
  .about-background-image-container {
    display: none;
  }
  .work-bannerImage-container{
    display: none;
  }
}

 .footer-section-bg {
  position: relative;
  background-color: #2a95dc75; 
  width: 100vw; 
  overflow: hidden; 
  margin-left: calc(-50vw + 50%); 
  padding-bottom: 100px; 
}


.footer-wrapper {
  display: flex;
  flex-direction: column;
  padding: 2rem 0.7rem;
  position: relative;
  z-index: 1;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100px; 
}



.footer-bg-container {
  position: absolute;
  top: 0;
  left: 0; 
  z-index: -1; 
  width: 100%; 
  height: 100px; 
}

.footer-section-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  position: relative;
  z-index: 1; 
}

.footer-logo-container img {
  max-width: 120px;
}


.footer-icons {
  display: flex;
  gap: 1rem;
  justify-content: center; 
  align-items: center; 
}


.footer-icons svg {
  font-size: 1.5rem;
  color: #585858;
  cursor: pointer;
  transition: color 0.3s;
  vertical-align: middle; 
}


.footer-icons svg:hover {
  color: #0d55fe;
}

.footer-section-two {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  gap: 2rem;
  position: relative;
  z-index: 1; 
}

.footer-column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.footer-column span {
  color: #4c4c4c;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.3s;
}

.footer-column span:hover {
  color: #0d55fe;
}

@media (max-width: 1000px) {
  .footer-wrapper {
    flex-direction: column;
    text-align: center;
  }

  .footer-section-two {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    margin-bottom: 1.5rem;
  }
}
.no-underline {
  text-decoration: none; 
}

.secondary-button {
  cursor: pointer; 
}
html {
  scroll-behavior: smooth; 
}