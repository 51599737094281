.product-section {
  padding: 90px;
  text-align: center;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 30px;
}

.product-card {
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-card:hover {
  transform: translateZ(0) scale(1.10);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.product-image {
  width: 100%; 
  height: 200px; 
  object-fit: cover; 
  border-radius: 40px;
}

.product-info {
  margin-top: 10px;
}

.product-info h3 {
  font-size: 20px;
  margin: 5px 0;
}

.product-info p {
  font-size: 16px;
  color: #555;
}



@media (max-width: 1024px) {
  .product-grid {
    grid-template-columns: repeat(2, 1fr); 
  }
  
  .product-info h3 {
    font-size: 16px;
  }

  .product-info p {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .product-grid {
    grid-template-columns: 1fr;
  }
  
  .product-info h3 {
    font-size: 14px;
  }

  .product-info p {
    font-size: 12px;
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%; 
  max-width: 500px; 
  text-align: center;
  position: relative;
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
}
